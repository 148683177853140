import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

import Layout from '/src/components/layout'
import { xyz } from '/src/styles/index.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShieldHeart, faO, faCircleNodes, faAngleRight, faHandPeace, faCircleCheck, faPeopleGroup, faCrown, faCircle } from '@fortawesome/free-solid-svg-icons'


const IndexPage = () => {
	return (
		<Layout showBox={false}>

			<div className="pageRows">

				<div>
					<div className={xyz}>
						<div className="rowText">
							<h1>Enkelt.<br/>Modernt.<br/>Tryggt.</h1>
							<p>
								<FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /> Förenklar vardagen<br/>
								<FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /> Både webbsida &amp; app<br/>
								<FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /> Schyssta priser &amp; villkor<br/>
							</p>
						</div>
						<div className="rowIcon">
							<StaticImage src="../images/devices.png" alt="Laddify" style={{width: '100%'}} />
						</div>
					</div>
				</div>

				<div>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faHandPeace} />
						</div>
						<div className="rowText">
							<h2>Enkelt</h2>
							<p>Alla delar av Brfify utvecklas med användarna i fokus för en enkel och smidig vardag, ja till och med prismodellen är enkel!</p>

							<table className="listTable">
								<tr>
									<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
									<td><b>Användarvänlig</b> app och webbsida som hanteras via <b>ett</b> admin-verktyg.</td>
								</tr>
								<tr>
									<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
									<td><b>Inga lösenord</b> för boende att hålla reda på (färre frågor till styrelsen).</td>
								</tr>
								<tr>
									<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
									<td><b>Smidigare vardag</b> för alla inblandade i en brf är Brfifys vision!</td>
								</tr>
							</table>
							
							<Link to="/funktioner" className="inlineButton big">Funktioner <FontAwesomeIcon icon={faAngleRight} /></Link>
						</div>
					</div>
				</div>


				<div>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faCrown} />
						</div>
						<div className="rowText">
							<h2>Modernt</h2>
							<p>Brfify är en modern plattform byggd med globala stabila ramverk och infrastruktur som även svenska banker, vård- &amp; energibolag med flera litar på vilket ger föreningar och fastighetsägare en modern och trygg plattform för sin digitalisering. </p>

							<table className="listTable">
								<tr>
									<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
									<td>Snygg, snabb och modern webbsida och app.</td>
								</tr>
								<tr>
									<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
									<td>Kraftfull plattform för att bidra till effektivisering och digitalisering av fastigheten.</td>
								</tr>
								<tr>
									<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
									<td>Byggd med moderna ramverk och infrastruktur i <a href="https://aws.amazon.com/what-is-aws/" target="_blank" rel="noreferrer">Amazon Web Services (AWS)</a>.</td>
								</tr>
								<tr>
									<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
									<td>Löpande nya och förbättrade funktioner, vilka vill du se?</td>
								</tr>
							</table>

							<Link to="/funktioner" className="inlineButton big">Funktioner <FontAwesomeIcon icon={faAngleRight} /></Link>
						</div>
					</div>
				</div>


				<div>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faShieldHeart} />
						</div>
						<div className="rowText">
							<h2>Tryggt</h2>
							<p>Med en enkel prismodell, schyssta villkor och en stabil plattform som ständigt blir bättre är det tryggt att vara kund hos Brfify.</p>

							<table className="listTable">
								<tr>
									<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
									<td>Inga dolda avgifter, ingen bindningstid &amp; ingen uppsägningstid.</td>
								</tr>
								<tr>
									<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
									<td>Betala per månad i efterhand.</td>
								</tr>
								<tr>
									<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
									<td>Byggd för hög tillgänglighet.</td>
								</tr>
								<tr>
									<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
									<td>Mall för GDPR (personuppgiftsbiträdesavtal), rekommenderad av Integritetsskyddsmyndigheten (IMY), tillhandahålls utan extra kostnad.</td>
								</tr>
								<tr>
									<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
									<td>Möjligt att skydda privat innehåll endast för inloggade användare.</td>
								</tr>
							</table>
						</div>
					</div>
				</div>


				<div>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faCircleNodes} />
						</div>
						<div className="rowText">
							<h2>Effektivare för alla</h2>

							<p className="intro">Brfifys vision är att förenkla vardagen för alla inblandade i en bostadsrättsförening eller hyresfastighet. Med snabba, användarvänliga och trygga digitala lösningar bidrar Brfify till att förenkla, underlätta och effektivisera vardagen.</p>

							<table className="listTable">
								<tr>
									<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
									<td>Medlemmar/boende</td>
								</tr>
								<tr>
									<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
									<td>Styrelse/fastighetsägare</td>
								</tr>
								<tr>
									<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
									<td>Förvaltare &amp; leverantörer</td>
								</tr>
								<tr>
									<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
									<td>Intressenter/köpare &amp; mäklare</td>
								</tr>
							</table>

							<Link to="/funktioner" className="inlineButton big">Funktioner <FontAwesomeIcon icon={faAngleRight} /></Link>
						</div>
					</div>
				</div>

			
				<div>
					<div>
						<div className="rowIcon">
							<p style={{ fontSize: '16px' }}>från</p>
							<p style={{ fontSize: '102px', lineHeight: 'normal' }}>3 kr</p>
						</div>
						<div className="rowText">
							<h2>Enkel prismodell</h2>

							<p>Med Brfifys tydliga prismodell är det enkelt att i förväg veta vad kostnaden blir. De två abonnemangen, Standard och Premium, har båda pris per lägenhet och månad med en minsta och högsta månadskostnad. Privata fastighetsägare får pris genom <Link to="/skaffa">offert</Link>.</p>

							<table style={{ textAlign: 'right' }}>
								<tr>
									<td colSpan="2" style={{ textAlign: 'right' }}>Månadskostnad:</td>
									<td style={{ color: 'gray' }}>Minst:</td>
									<td style={{ color: 'gray' }}>Max:</td>
								</tr>
								<tr>
									<td><span style={{whiteSpace: 'nowrap'}}><FontAwesomeIcon icon={faCircle} className="checkGreen" /> Standard</span></td>
									<td>3 kr/lgh</td>
									<td style={{ color: 'gray' }}>99 kr</td>
									<td style={{ color: 'gray' }}>349 kr</td>
								</tr>
								<tr>
									<td><span style={{whiteSpace: 'nowrap'}}><FontAwesomeIcon icon={faCircle} className="checkGold" /> Premium</span></td>
									<td>5 kr/lgh</td>
									<td style={{ color: 'gray' }}>199 kr</td>
									<td style={{ color: 'gray' }}>499 kr</td>
								</tr>
							</table>
							
							{ /*
							<p>Månadskostnaden är <span className="highlightedText">3 kronor / lägenhet</span> exklusive moms för Brfify Standard,<br/>
							men är totalt <span style={{whiteSpace: 'nowrap'}}>minst 99 kronor</span> och totalt <span className="highlightedText">max 349 kronor</span>.</p>

							<p>Månadskostnaden är <span className="highlightedText">5 kronor / lägenhet</span> exklusive moms för Brfify Premium,<br/>
							men är totalt<span style={{whiteSpace: 'nowrap'}}>minst 199 kronor</span> och totalt <span className="highlightedText">max 499 kronor</span>.</p>
							*/}

							<p><br/></p>

							<p><span className="highlightedText">Första månaden är gratis</span> så att ni i lugn och ro kan komma igång med Brfify.</p>

							<p><br/></p>

							<Link to="/pris" className="inlineButton big">Pris <FontAwesomeIcon icon={faAngleRight} /></Link>
						</div>
					</div>
				</div>


				<div>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faO} />
						</div>
						<div className="rowText">
							<h2>Mer av inget!</h2>
							<p>Brfify har en tydlig prismodell och vänliga villkor. Exempelvis finns <span className="highlightedTextTight">inga dolda avgifter</span>, <span className="highlightedTextTight">ingen startavgift</span>, <span className="highlightedTextTight">ingen uppsägningsavgift</span>, <span className="highlightedTextTight">ingen uppsägningstid</span> och <span className="highlightedTextTight">ingen bindingstid</span>.</p>
							<p>Målet är att föreningar/fastighetsägare stannar kvar för att de vill, inte för att de måste.</p>

							<Link to="/pris" className="inlineButton big">Pris <FontAwesomeIcon icon={faAngleRight} /></Link>
						</div>
					</div>
				</div>


				{ /*
				<div>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faPeopleGroup} />
						</div>
						<div className="rowText">
							<h2>Samarbeta</h2>
							<p>Med Brfify är det enkelt att samarbeta med andra föreningar, exempelvis genom att <span className="highlightedText">dela bokningsobjekt</span> såsom bastu, övernattningslägenhet eller gemensamhetslokal. Eller kanske finns det <span className="highlightedText">gemensamma parkeringsplatser</span>?</p>

							<p>När det med samma plattform ser ut och fungerar på samma sätt är det även enkelt att hjälpas åt med problem, tips eller idéer.</p>

							<p>Som fastighetsägare kan du använda samma plattform till alla dina hyresbostäder.</p>

							<Link to="/funktioner" className="inlineButton big">Funktioner <FontAwesomeIcon icon={faAngleRight} /></Link>
						</div>
					</div>
				</div>
				*/}

			</div>

		</Layout>
	)
}

export default IndexPage